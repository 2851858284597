<template>
    <div>
        <main>
            <section class="hero">
                <h1>欢迎来到我的博客</h1>
                <p>分享我的编程旅程和技术见解。</p>
            </section>
            <section class="posts">
                <!-- 这里可以动态添加文章 -->
            </section>
        </main>
    </div>
</template>

<script>
export default {
    name: 'homePage',
};
</script>

<style scoped src="@/assets/css/homepage.css">
</style>